import React, { FC, useEffect, useState } from 'react';
import { components } from 'react-select';
import {
  Avatar,
  Button,
  Field,
  Icon,
  IconButton,
  mergeTailwindClasses,
  Select,
  TextArea,
  Tooltip,
} from '@kontentino/ui';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SocialMediaTypeSelect from 'app/components/modals/addNewProfile/addNewProfileManually/SocialMediaTypeSelect';
import clsx from 'clsx';
import { useBrandHubNewCreativeForm } from 'app/modules/brandHub/components/brandHubCreatives/brandHubNewCreativeForm/BrandHubNewCreativeFormProvider';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { BrandHubApi } from 'app/modules/brandHub/api/brandHub';
import useBoolean from 'utils/hooks/useBoolean';
import BrandHubManageProfileModalForm from 'app/modules/brandHub/components/modals/brandHubManageProfileModal/brandHubManageProfileModalForm';
import Modal from 'components/shared/Modal';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { BrandProfile } from 'app/modules/brandHub/types';
import { GenerationsApi } from 'app/modules/posts/api/GenerationsApi';
import { useLastSelectOptions } from 'app/hooks/useLastSelectOptions';
import WebCrawlLinkField from 'app/modules/brandHub/components/WebCrawlLinkField';

type Props = {
  className?: string;
};

const BrandHubNewCreativeForm: FC<Props> = ({ className }) => {
  const [manageProfileOpen, setIsManageProfileOpen] = useBoolean(false);
  const [newBrandName, setNewBrandName] = useState<string | undefined>();
  const [profileToEdit, setProfileToEdit] = useState<
    BrandProfile | undefined
  >();
  const { form, brandHubProfiles, initialValues } =
    useBrandHubNewCreativeForm();
  const creativesOptions = useQuery(
    queryKey.brandHubCreativesOptions(),
    BrandHubApi.creativesOptions,
  );
  const { t } = useTranslation();
  const translateTextOptions = useQuery(
    queryKey.transformTextOptions(),
    GenerationsApi.getTransformTextOptions,
  );
  const lastSelectOptions = useLastSelectOptions();

  function onClose() {
    setIsManageProfileOpen.off();
    setNewBrandName(undefined);
    setProfileToEdit(undefined);
  }

  useEffect(() => {
    const values = form.getValues();

    function preselectBranProfile() {
      if (values.brandProfileId || initialValues?.brandProfileId) return;

      if (!values.brandProfileId && lastSelectOptions.brandProfileId[0]) {
        const preselectBranProfile = brandHubProfiles.data?.find(
          (profile) => profile.id === lastSelectOptions.brandProfileId[0].value,
        );

        if (preselectBranProfile) {
          form.setValue('brandProfileId', preselectBranProfile.id);
        }
      }
    }

    preselectBranProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    brandHubProfiles.data,
    lastSelectOptions.brandProfileId,
    creativesOptions.data,
  ]);

  return (
    <div
      className={mergeTailwindClasses(
        clsx('tw-space-y-4 tw-overflow-auto', className),
      )}
    >
      {initialValues?.brandProfileId && (
        <Field.Group>
          <Field.Label required>Target social media</Field.Label>
          <Controller
            name="pageType"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <div
                data-name="brand-hub_create-creative_page-type_select-wrapper"
                data-cy="brand-hub_create-creative_page-types_elect-wrapper"
              >
                <SocialMediaTypeSelect
                  data-name="brand-hub_create-creative_page-type"
                  data-cy="brand-hub_create-creative_page-type"
                  value={value ? { value } : undefined}
                  onSelect={(option) => onChange(option)}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (styles: any) => ({
                      ...styles,
                      zIndex: 1010,
                    }),
                  }}
                />
              </div>
            )}
          />
          {!!form.formState.errors.pageType?.message && (
            <Field.Error>{form.formState.errors.pageType?.message}</Field.Error>
          )}
        </Field.Group>
      )}
      {!initialValues?.brandProfileId && (
        <>
          <Field.Group>
            <Field.Label required>{t('brandVoice')}</Field.Label>
            <Controller
              name="brandProfileId"
              control={form.control}
              render={({ field: { value, onChange } }) => {
                const selectedProfile = brandHubProfiles.data?.find(
                  (profile) => profile.id === value,
                );
                const selectValue = selectedProfile
                  ? {
                      value: selectedProfile.id,
                      label: selectedProfile.name,
                    }
                  : null;

                const groupedOptions = lastSelectOptions.getGroupedOptions(
                  'brandProfileId',
                  brandHubProfiles?.data?.map((profile) => ({
                    value: `${profile.id}`,
                    label: profile.name,
                  })) || [],
                );

                return (
                  <div
                    data-name="brand-hub_create-creative_brand-profile_select-wrapper"
                    data-cy="brand-hub_create-creative_brand-profile_select-wrapper"
                  >
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (styles) => ({
                          ...styles,
                          zIndex: 1010,
                        }),
                      }}
                      value={selectValue}
                      isClearable
                      placeholder={t('selectBrandVoice')}
                      formatOptionLabel={(option) => (
                        <div className="tw-flex tw-items-center">
                          <Avatar
                            size={26}
                            name={option.label}
                            src={
                              brandHubProfiles?.data?.find(
                                (profile) => profile.id === option.value,
                              )?.logoSrc
                            }
                          />
                          <span className="tw-ml-2">{option.label}</span>
                        </div>
                      )}
                      components={{
                        Option: ({ children, ...props }) => (
                          <components.Option {...props}>
                            <div className="tw-flex tw-items-center">
                              <Avatar
                                size={26}
                                name={props.data.label}
                                src={
                                  brandHubProfiles?.data?.find(
                                    (profile) =>
                                      profile.id === props.data.value,
                                  )?.logoSrc
                                }
                                className="tw-mr-2"
                              />
                              <span className="tw-truncate tw-font-regular">
                                {props.data.label}
                              </span>
                              <Tooltip content={t('edit')}>
                                <IconButton
                                  variant="ghost"
                                  size="small"
                                  className="tw-ml-auto tw-hidden tw-text-grayscale-100 data-[state=focused]:tw-block"
                                  data-state={
                                    props.isFocused ? 'focused' : 'unfocused'
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const profileToEdit =
                                      brandHubProfiles.data?.find(
                                        (profile) =>
                                          profile.id === props.data.value,
                                      );

                                    if (profileToEdit) {
                                      setIsManageProfileOpen.on();
                                      setProfileToEdit(profileToEdit);
                                    }
                                  }}
                                >
                                  <Icon icon={faEdit} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </components.Option>
                        ),
                      }}
                      onChange={(option) => {
                        onChange(option?.value);
                        if (option) {
                          lastSelectOptions.addOption('brandProfileId', option);
                        }
                      }}
                      options={groupedOptions}
                      isCreatable
                      onCreateOption={(value) => {
                        setNewBrandName(value);
                        setIsManageProfileOpen.on();
                      }}
                    />
                    <Button
                      onClick={() => setIsManageProfileOpen.on()}
                      data-name="brand-create_creative_hub_new-brand-profile_create-profile"
                      variant="tertiary"
                      size="small"
                      className="tw-mt-2"
                    >
                      {t('createBrandProfile')}
                    </Button>
                  </div>
                );
              }}
            />
            {!!form.formState.errors.brandProfileId?.message && (
              <Field.Error>
                {form.formState.errors.brandProfileId?.message}
              </Field.Error>
            )}
          </Field.Group>
        </>
      )}
      <Field.Group>
        <Field.Label required>{t('goalOfYourPost')}</Field.Label>
        <TextArea
          rows={3}
          {...form.register('goal')}
          data-name="brand-hub_create-creative_goal"
          data-cy="brand-hub_create-creative_goal"
          placeholder={t('goalOfYourPostPlaceholder')}
        />
        {!!form.formState.errors.goal?.message && (
          <Field.Error>{form.formState.errors.goal?.message}</Field.Error>
        )}
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('brandHubReferenceLink')}</Field.Label>
        <Field.Caption>
          {t('creativeFormReferenceLinkDescription')}
        </Field.Caption>
        <Controller
          control={form.control}
          name="webCrawl"
          render={({ field }) => (
            <WebCrawlLinkField
              value={field.value}
              onChange={field.onChange}
              dataNamePrefix="brand-hub_create-creative"
            />
          )}
        />
        {!!form.formState.errors.webCrawl?.message && (
          <Field.Error>
            <>{form.formState.errors.webCrawl?.message}</>
          </Field.Error>
        )}
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('toneOfVoice')}</Field.Label>
        <Controller
          name="toneOfVoice"
          control={form.control}
          render={({ field: { value, onChange } }) => {
            const selectValue =
              creativesOptions?.data?.toneOfVoice?.find(
                (option) => option.value === value,
              ) || null;

            return (
              <div
                data-name="brand-hub_create-creative_tone-of-voice_select-wrapper"
                data-cy="brand-hub_create-creative_tone-of-voice_select-wrapper"
              >
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (styles) => ({
                      ...styles,
                      zIndex: 1010,
                    }),
                  }}
                  value={selectValue}
                  isClearable
                  placeholder={t('selectToneOfVoice')}
                  onChange={(option) => {
                    onChange(option?.value);
                  }}
                  options={creativesOptions.data?.toneOfVoice || []}
                />
              </div>
            );
          }}
        />
        {!!form.formState.errors.toneOfVoice?.message && (
          <Field.Error>
            {form.formState.errors.toneOfVoice?.message}
          </Field.Error>
        )}
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('lengthOfCreatives')}</Field.Label>
        <Controller
          name="creativeLength"
          control={form.control}
          render={({ field: { value, onChange } }) => {
            const selectValue =
              creativesOptions?.data?.creativeLength?.find(
                (option) => option.value === value,
              ) || null;

            return (
              <div
                data-name="brand-hub_create-creative_length_select-wrapper"
                data-cy="brand-hub_create-creative_length_select-wrapper"
              >
                <Select
                  data-name="brand-hub_create-creative_length"
                  data-cy="brand-hub_create-creative_length"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (styles) => ({
                      ...styles,
                      zIndex: 1010,
                    }),
                  }}
                  value={selectValue}
                  formatOptionLabel={({ value }) =>
                    t(`creativesLength.${value || 'notSpecified'}`)
                  }
                  onChange={(option) => onChange(option?.value)}
                  options={creativesOptions?.data?.creativeLength || []}
                />
              </div>
            );
          }}
        />
        {!!form.formState.errors.creativeLength?.message && (
          <Field.Error>
            {form.formState.errors.creativeLength?.message}
          </Field.Error>
        )}
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('outputLanguage')}</Field.Label>
        <Controller
          name="language"
          control={form.control}
          render={({ field: { onChange, value, ref } }) => {
            const languages = translateTextOptions.data?.options.language ?? [];

            const selectedValue =
              languages.find((language) => language.value === value) ?? null;

            const groupedOptions = lastSelectOptions.getGroupedOptions(
              'language',
              languages,
            );

            return (
              <div
                ref={ref}
                tabIndex={0}
                data-name="translate-text_language_select-wrapper"
                data-cy="translate-text_language_select-wrapper"
              >
                <Select
                  options={groupedOptions}
                  isClearable
                  placeholder="Using brand voice language"
                  onChange={(option) => {
                    if (option?.value) {
                      lastSelectOptions.addOption('language', option);
                    }
                    onChange(option?.value ?? null);
                  }}
                  value={selectedValue}
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  styles={{
                    menuPortal: (styles) => ({
                      ...styles,
                      zIndex: 1010,
                    }),
                  }}
                />
              </div>
            );
          }}
        />
        {form.formState.errors.language?.message && (
          <Field.Error>{form.formState.errors.language?.message}</Field.Error>
        )}
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('countOfCreatives')}</Field.Label>
        <Controller
          name="creativeCount"
          control={form.control}
          render={({ field: { value, onChange } }) => (
            <div
              data-name="brand-hub_create-creative_count_select-wrapper"
              data-cy="brand-hub_create-creative_count_select-wrapper"
            >
              <Select
                data-name="brand-hub_create-creative_count"
                data-cy="brand-hub_create-creative_count"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (styles) => ({
                    ...styles,
                    zIndex: 1010,
                  }),
                }}
                value={{
                  value,
                  label: t('countOfCreatives', { count: value }),
                }}
                onChange={(option) => onChange(option?.value)}
                options={creativesOptions.data?.counts || []}
              />
            </div>
          )}
        />
        {!!form.formState.errors.creativeCount?.message && (
          <Field.Error>
            {form.formState.errors.creativeCount?.message}
          </Field.Error>
        )}
      </Field.Group>
      <Modal
        open={manageProfileOpen}
        onClose={setIsManageProfileOpen.off}
        dataName={{
          closeButton: 'brand-hub_manage-brand-profile_close',
          wrapper: 'brand-hub_manage-brand-profile_modal',
        }}
        dataCy={{
          closeButton: 'brand-hub_manage-brand-profile_close',
          wrapper: 'brand-hub_manage-brand-profile_modal',
        }}
      >
        <BrandHubManageProfileModalForm
          onClose={onClose}
          profileToEdit={profileToEdit}
          initialValues={{
            name: newBrandName,
          }}
          eventHandlers={{
            onProfileSave: (profile) => {
              form.setValue('brandProfileId', profile.id);
            },
            onRemainingProfilesChange: (profiles) => {
              const profileId = form.getValues().brandProfileId;

              if (
                profileId &&
                !profiles.find(
                  (remainingProfiles) => remainingProfiles.id === profileId,
                )
              ) {
                form.setValue('brandProfileId', profiles[0]?.id || undefined);
              }
            },
          }}
        />
      </Modal>
    </div>
  );
};

export default BrandHubNewCreativeForm;
